<template>
  <!-- 运行监管 - 意见反馈 -->
  <div class="pageContol feedback">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">意见反馈</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 检索条件 -->
        <div
          class="operationControl flexdc"
          style="align-items: flex-start; padding: 0 0.675rem"
        >
          <el-form ref="seachData" :model="seachData" inline label-width="70px">
            <div class="searchbox" style="margin-bottom: 5px">
              <el-form-item label="账号">
                <el-input
                  v-model="seachData.accountNumber"
                  clearable
                  size="small"
                  placeholder="请输入账号"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input
                  v-model="seachData.fullName"
                  clearable
                  size="small"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="反馈类型">
                <el-select
                  v-model="seachData.feedbackType"
                  clearable
                  size="small"
                  placeholder="请选择反馈类型"
                >
                  <el-option
                    v-for="item in feedbackTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
               <el-form-item label="回复人">
                <el-input
                  v-model="seachData.replyName"
                  clearable
                  size="small"
                  placeholder="请输入回复人"
                ></el-input>
              </el-form-item>
            </div>
            <div class="searchbox" style="margin-bottom: 5px">
              <el-form-item label="分类">
                <el-select
                  v-model="seachData.feedbackReplyType"
                  placeholder="请选择分类"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in feedbackReplayTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
               <el-form-item label="处理状态">
                <el-select
                  v-model="seachData.feedbackState"
                  clearable
                  size="small"
                  placeholder="请选择处理状态"
                >
                  <el-option
                    v-for="item in processingStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="反馈时间">
                <el-date-picker
                  clearable
                  size="small"
                  v-model="seachData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="margin-left: 20px"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                min-width="50"
                fixed
              ></el-table-column>
              <el-table-column
                label="账号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="150"
                fixed
              ></el-table-column>
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="联系电话"
                align="left"
                prop="feedbackMobile"
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="反馈类型"
                align="left"
                show-overflow-tooltip
                prop="feedbackType"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.feedbackType == '10'">功能异常</span>
                  <span v-else-if="scope.row.feedbackType == '20'"
                    >优化建议</span
                  >
                  <span v-else-if="scope.row.feedbackType == '30'"
                    >其他反馈</span
                  >
                  <span v-else>- -</span>
                </template>
              </el-table-column>
              <el-table-column
                label="具体描述"
                align="left"
                prop="feedbackDescribe"
                show-overflow-tooltip
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="反馈时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="分类"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="160"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "FEEDBACKREPLYTYPE",
                      scope.row.feedbackReplyType
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="处理状态"
                align="left"
                show-overflow-tooltip
                prop="feedbackState"
                width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("FEEDBACKSTATE", scope.row.feedbackState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="回复人"
                align="left"
                show-overflow-tooltip
                prop="replyName"
                width="100"
              >
              </el-table-column>
              <el-table-column
                label="回复内容"
                align="left"
                show-overflow-tooltip
                prop="feedbackReplyDescribe"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.feedbackReplyDescribe }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="100" fixed="right">
                <template slot-scope="scope">
                  <!-- <el-button type="text" @click="seeInfo(scope.row)">详情</el-button>
                  <el-button type="text" @click="seeInfo(scope.row)">分类</el-button> -->

                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="seeInfo(scope.row)"
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="classification(scope.row)"
                    >分类</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 详情的 Dialog 对话框 -->
    <el-dialog title="反馈详情" :visible.sync="dialogVisible1" width="40%">
      <div style="max-height: 500px; overflow: auto; padding: 0 10px">
        <el-form
          label-position="right"
          class="demo-table-expand"
          label-width="100px"
          :model="dialogData"
          :disabled="replayState"
          ref="dialogData"
          :rules="dialogDataRules"
        >
          <el-form-item label="反馈类型：">
            <span v-if="dialogData.feedbackType == '10'">功能异常</span>
            <span v-if="dialogData.feedbackType == '20'">优化建议</span>
            <span v-if="dialogData.feedbackType == '30'">其他反馈</span>
          </el-form-item>
          <el-form-item label="具体描述：">
            <span>{{ dialogData.feedbackDescribe }}</span>
          </el-form-item>
          <el-form-item label="APP版本号：">
            <span>{{ dialogData.versionNumber }}</span>
          </el-form-item>
          <el-form-item label="手机型号：">
            <span>{{ dialogData.phoneModel }}</span>
          </el-form-item>
          <el-form-item label="附件照片：">
            <span v-show="!dialogData.images || !dialogData.images.length"
              >暂未上传反馈图片</span
            >
            <div style="display: flex; flex-wrap: wrap">
            <div
              v-show="dialogData.images.length"
              class="block "
              v-for="item in dialogData.images"
              :key="item"
            >
              <img :src="item" />
            </div>
          </div>
          </el-form-item>
          
          <el-form-item label="回复人：" v-if="dialogData.replyName">
            <span>{{ dialogData.replyName }}</span>
          </el-form-item>
          <el-form-item label="回复时间：" v-if="dialogData.replyTime">
            <span>{{ dialogData.replyTime | moment }}</span>
          </el-form-item>
          <el-form-item label="回复内容：" prop="feedbackReplyDescribe">
            <el-input
              type="textarea"
              placeholder="请输入回复内容"
              v-model="dialogData.feedbackReplyDescribe"
            />
          </el-form-item>
          <el-form-item
            label="分类："
            prop="feedbackReplyType"
            style="margin-top: 20px"
          >
            <el-select
              v-model="dialogData.feedbackReplyType"
              placeholder="请选择分类"
              size="small"
              clearable
            >
              <el-option
                v-for="item in feedbackReplayTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center; margin-top: 15px">
          <el-button @click="doCloseDialogVisible1()">取消</el-button>
          <el-button
           :disabled="replayState"
            class="bgc-bv"
            @click="submitForm('dialogData')"
            >回复</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="分类"
      :visible.sync="dialogVisible2"
      width="30%"
      center
      class="relation_jg_dialog"
      :close-on-click-modal="false"
      @close="doClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rulesForm"
        ref="ruleForm"
        label-width="8rem"
        class="demo-ruleForm"
        style="width: 90%"
      >
        <el-form-item label="分类：" prop="feedbackReplyType">
          <el-select
            v-model="ruleForm.feedbackReplyType"
            placeholder="请选择分类"
            size="small"
            clearable
          >
            <el-option
              v-for="item in feedbackReplayTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="submitReplyTypeForm('ruleForm')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; // 引入表格无数据提示 - 组件
import PageNum from "@/components/PageNum.vue"; // 引入分页 - 组件
import List from "@/mixins/List"; // 引入列表 - 混入
export default {
  name: "feedback",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      seachData: {
        accountNumber: "", // 账号
        fullName: "", // 姓名
        feedbackType: "", // 反馈类型
        createTime: "", //反馈时间
        feedbackReplyType: "", //分类
        replyName:'', //回复人
        feedbackState:'', //回复状态
      },
      // 反馈类型 - 下拉数据
      feedbackTypes: [],
      // 处理状态 - 下拉数据
      processingStatus: [],
      // 详情的 Dialog 对话框的数据
      dialogData: {
        feedbackReplyDescribe: "",
      },
      // 详情的 Dialog 对话框 - 显示/隐藏
      dialogVisible1: false,
      dialogVisible2: false,
      ruleForm: {
        feedbackReplyType: "",
      },
      rulesForm: {
        feedbackReplyType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      dialogDataRules:{
        feedbackReplyDescribe: [
          { required: true, message: "请输入回复内容进行回复", trigger: "blur" },
        ],
      },
      replayState: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    this.getFeedbackTypeDictionaries();
  },
  methods: {
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        mobile: this.seachData.accountNumber,
        userName: this.seachData.fullName,
        feedbackType: this.seachData.feedbackType,
        feedbackReplyType: this.seachData.feedbackReplyType,
        pageNum: pageNum,
        pageSize: this.pageSize,
        replyName: this.seachData.replyName,
        feedbackState:this.seachData.feedbackState
      };
      if (this.seachData.createTime && this.seachData.createTime.length > 0) {
        params.startDateLong = this.seachData.createTime[0];
        params.endDateLong = this.seachData.createTime[1];
      }
      this.doFetch({
        url: "/sys/feedback/selectFeedbackPage",
        params,
        pageNum,
      });
    },
    // 查看详情
    seeInfo(row) {
      this.dialogVisible1 = true;
      this.dialogData = row;
      console.log(row.feedbackState);
      if (row.feedbackState == "10") {
        this.replayState = false;
      } else {
        this.replayState = true;
      }
    },
    //分类
    classification(row) {
      this.dialogVisible2 = true;
      this.feedbackId = row.feedbackId;
      this.ruleForm.feedbackReplyType = row.feedbackReplyType;
      // this.getSelectData(row.feedbackId)
    },
    submitReplyTypeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/sys/feedback/category", {
            feedbackId: this.feedbackId,
            feedbackReplyType: this.ruleForm.feedbackReplyType,
          }).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.dialogVisible2 = false;
              this.getData();
            }
          });
        }
      });
    },
    doClose() {
      this.dialogVisible2 = false;
      this.ruleForm.feedbackReplyType = "";
    },
    //回复确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/sys/feedback/reply", {
            feedbackId: this.dialogData.feedbackId,
            feedbackReplyId: this.dialogData.feedbackReplyId,
            feedbackReplyDescribe: this.dialogData.feedbackReplyDescribe,
            feedbackReplyType: this.dialogData.feedbackReplyType,
          }).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.dialogVisible1 = false;
              this.dialogData = {};
              this.getData(-1);
            }
          });
        }
      });
    },
    // 获取反馈类型字典
    getFeedbackTypeDictionaries() {
      const loginResultList = this.$setDictionary("FEEDBACKTYPE", "list");
      const feedbackreplayTypeList = this.$setDictionary(
        "FEEDBACKREPLYTYPE",
        "list"
      );
       const processingStatus = this.$setDictionary("FEEDBACKSTATE", "list" );

      const list = [],
        feedreplayTypeList = [],processingStatusList=[];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in loginResultList) {
        list.push({
          value: key,
          label: loginResultList[key],
        });
      }
      for (const key in feedbackreplayTypeList) {
        feedreplayTypeList.push({
          value: key,
          label: feedbackreplayTypeList[key],
        });
      }
        processingStatusList.push({
        value: "",
        label: "全部",
      });
      for (const key in processingStatus) {
        processingStatusList.push({
          value: key,
          label: processingStatus[key],
        });
      }
      this.feedbackTypes = list;
      this.feedbackReplayTypes = feedreplayTypeList;
      this.processingStatus = processingStatusList;
    },
    doCloseDialogVisible1() {
      this.dialogVisible1 = false;
      this.dialogData.feedbackReplyDescribe = '';
      this.dialogData.feedbackReplyType = '';
      this.getData(-1)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.searchList {
  padding: 0 !important;
  .listLabel {
    margin-left: 10px;
  }
  .listInput {
    width: 90%;
  }
}
.feedback /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.el-form-item {
  margin-bottom: 0;
  span {
    color: #909399;
    font-size: 12px;
  }
}
// .feedback /deep/ .el-table--enable-row-transition .el-table__body td {
//   padding: 0;
// }
.feedback /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 50%;
    position: relative;
    img {
      width: 90% !important;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
.feedback /deep/ .el-textarea__inner {
  min-height: 7.5rem;
  resize: none;
}
</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
